import { useState } from 'react';
import {
    FormProvider,
    FormSubmitButton,
} from "@hilma/forms";
import * as yup from 'yup';
import axios from "axios";
import Alert from '@mui/material/Alert/Alert';
import { Box, Fade, Grow, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import TextInput from '../components/TextInput';

import '../style/ChangePasswordPage.scss'

interface changePasswordPopupProps {
    isOpen: boolean,
    setIsOpen: (open: boolean) => void,
}
const ChangePasswordPage = ({isOpen, setIsOpen}:changePasswordPopupProps) => {
    const [openAlert, setOpenAlert] = useState(false)
    const inputSchema = yup.string().required('שדה זה הינו חובה').min(8, 'הסיסמה צריכה להכיל לפחות 8 תווים').matches(/.*\d.*/, 'הסיסמה צריכה להכיל לפחות מספר אחד')

    let formSchema = yup.object({
        oldPassword: yup.string().required('שדה זה הינו חובה'),
        newPassword: inputSchema,
        validateNewPassword: inputSchema.oneOf([yup.ref('newPassword'), null], 'הסיסמה אינה תואמת'),
    });

    async function changePassword(oldPassword: string, newPassword: string, formBag) {
        const res = await axios.post('/api/admin/change-password', { oldPassword, newPassword })
        if (res.data.message === 'Passwords does not match.') {
            formBag.setFieldError('oldPassword', 'הסיסמה אינה נכונה')
        } else {
            setOpenAlert(true)
        }
    }

    const handleCancel = () => {
        setIsOpen(false)
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleCancel}
            className='add-instructor-pop-up-container'
            closeAfterTransition>

            <Fade in={isOpen}>
                <Box className='add-instructor-pop-up'>
                    <div className='close-icon'>
                        <CloseIcon sx={{ fontSize: '2vw', opacity:'0.6' }} onClick={handleCancel} />
                    </div>
                    <Typography className='title'>שינוי סיסמה</Typography>
                    <FormProvider
                        initialValues={{
                            oldPassword: "",
                            newPassword: "",
                            validateNewPassword: ""
                        }}
                        dir="ltr"
                        onSubmit={(value, formBag) => { changePassword(value.oldPassword, value.newPassword, formBag); }}
                        validationSchema={formSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        <TextInput isPassword label='סיסמה ישנה' name={'oldPassword'} />
                        <TextInput isPassword label='סיסמה חדשה' name={'newPassword'} />
                        <TextInput isPassword label='אימות סיסמה חדשה' name={'validateNewPassword'} />
                        <FormSubmitButton>אישור</FormSubmitButton>
                    </FormProvider>
                    <Grow className='success-alert' in={openAlert}>
                        <Alert onClose={() => setOpenAlert(false)} severity="success">!הסיסמה שונתה בהצלחה</Alert>
                    </Grow>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ChangePasswordPage
