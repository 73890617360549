import QuestionnaireLengthFirstPart from "./QuestionnaireLength"
import ReturnUsersPart from "./ReturnUsersFirstPart"
import TimeFirstPart from "./TimeFirstPart"
import UnfinishedPeopleFirstPart from "./UnfinishedPeopleFirstPart"

import '../../style/GeneralStatistics.scss'

const GeneralStatisticsFirstPart = () => {
    const width = "14vw"
    const height = "17vh"
    return (
        <div className="general-statistics-first-part">
            <TimeFirstPart width={width} height={height} />
            <ReturnUsersPart width={width} height={height}/>
            <UnfinishedPeopleFirstPart width={width} height={height}/>
            <QuestionnaireLengthFirstPart width={width} height={height}/>
        </div>
    )
}
export default GeneralStatisticsFirstPart