import React, { useEffect } from "react";

import { useSignInServer } from "../common";
import { HomeHeader, GeneralStatistics, AnswersStatistics, DatesStatistics } from "../components";

import '../style/Home.scss'

const StatisticsPage = () => {
    const bringTheDataSignIn = useSignInServer()
    useEffect(()=>{
        bringTheDataSignIn()
    },[])
    
    return (
        <div className="home-page">
            <div className="home-container">
                <HomeHeader />
                <GeneralStatistics />
                <AnswersStatistics />
                <DatesStatistics/>
            </div>
        </div>
    )
}
export default StatisticsPage;