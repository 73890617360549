import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { SECTIONS, useStatisticsContext, whiteSquareSizeType } from '../../common';
import { GenericHeader, GenericTooltip, GenericWhiteSquare } from '../../generic-components';
import NoData from '../NoData';

import '../../style/GeneralStatistics.scss';

const yAxisStyle = {
  fontSize: '0.8em',
  fill: '#41596B',
  fontFamily: 'Heebo-Medium',
  dir: 'rtl'
};

const xAxisStyle = {
  fontSize: '0.9em',
  fontFamily: 'Heebo-Medium',
  fill: '#41596B',
};
const toolTipStyle = {
  backgroundColor: ' #F5EEEE',
  color: '#43484C',
  borderRadius: '3px',
  fontFamily: 'assistant',
  outline: 'none'
}

const AverageScoreSectionForthPart = ({ height, width }: whiteSquareSizeType) => {
  const { data } = useStatisticsContext();
  const pieData = data.sectionsStatistics !== null && data.sectionsStatistics.sectionStatistics.map((section, index) => {
    return {
      name: SECTIONS[section.name],
      average: section.average,
    }
  })
  const noDataTest = data.sectionsStatistics !== null && pieData.every(data => data.average === 0)

  const CustomXAxisTick = ({ x, y, payload }) => {
    const words = payload.value.split(' ');

    return (
      <g transform={`translate(${x},${y - 10})`}>
        <text x={0} y={0} textAnchor="middle" fill="#666">
          {words.map((word, index) => {
            return (
              <tspan x={0} dy="1em" style={xAxisStyle} key={index}>{word}</tspan>

            )
          })}
        </text>
      </g>
    );
  };
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const obj = payload[0].payload
      return (
        <GenericTooltip height='17vh' width='22vw'>
          <div>
            <p className="tooltip-content">{`ניקוד ממוצע של: ${obj.average.toFixed(Number.isInteger(obj.average) ? 0 : 2)}`}</p>
            <p className="tooltip-content">{`בפרק: ${obj.name}`}</p>
          </div>
        </GenericTooltip>
      );
    }
    return null;
  };
  return (
    <div>
      <GenericHeader text="ניקוד ממוצע ביחס לפרקים" />
      <GenericWhiteSquare  width={width} height={height}>
        {data.sectionsStatistics === null || noDataTest
          ? <NoData />
          :
          <ResponsiveContainer height={'90%'} width={'100%'} >
            <BarChart data={pieData} margin={{ top: 10, right: 30, left: 0, bottom: 43 }}>
              <CartesianGrid
                stroke="#E0E0E0"
                strokeWidth={1}
                vertical={false}
              />
              <XAxis dataKey="name"
                tickLine={false}
                tick={CustomXAxisTick}
                interval={0}
                tickFormatter={(name) => name.length > 10 ? `${name.substring(0, 10)}...` : name}
              />
              <YAxis
                tickLine={false}
                dx={-15}
                tick={yAxisStyle}
              />
              <Tooltip
                contentStyle={toolTipStyle}
                content={<CustomTooltip />}
                cursor={{ fill: 'transparent' }}
              />
              <Bar dataKey="average"
                fill="#00B4A9"
                barSize={30}
              />
            </BarChart>
          </ ResponsiveContainer >
        }
      </GenericWhiteSquare>
    </div>
  );
};

export default AverageScoreSectionForthPart;
