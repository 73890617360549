import { FormTextInput } from "@hilma/forms"
import { FC, useState } from "react"
import '../style/TextInput.scss'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

/**
 * @param label // the text label of the input
 * @param name // the name of the input (for hilma forms)
 * @param isPassword //whether the input is a password -boolean optional
 * note that in order to use this component it must be wrapped in form provider
 * in order to make css of label look like XD import from TextInputLabelMixin and add wrap it with the classname of FormProvider container. see example in ChangePasswordPage.scss 
 */

interface Props {
    label: string,
    name: string
    isPassword?: boolean
    showPasswordSrc?: string
    eyeHiddenSrc?: string
    onClick?: () => void
}

const TextInput: FC<Props> = ({ label, isPassword, name, onClick, showPasswordSrc = './showPassword.svg', eyeHiddenSrc = './eyeHidden.svg' }) => {
    const [showText, setShowText] = useState(false)
    return (
        <div className="password-input-container">
            <FormTextInput
                name={name}
                type={showText || !isPassword ? 'text' : "password"}
                label={label}
                dir={'rtl'}
                containerClassName='text-input-container'
                onClick={onClick}
            />
            {isPassword &&
                <div className="show-text-button" onClick={() => setShowText(prev => !prev)}>
                    {
                        showText
                            ? <VisibilityIcon  sx={{height: '2.5vh', width: '2.5vh'}}/>
                            : <VisibilityOffIcon sx={{height: '2.5vh', width: '2.5vh'}}/>
                    }
                </div>
            }
        </div>
    )
}

export default TextInput