import moment, { Moment } from "moment"
import { useStatisticsContext } from "../context"
import axios from "axios"
import { DateToMidnight, firstDateOfTheYear } from "."

const GetTheCorrectDateDivide = () => {
  const { endDate, startDate } = useStatisticsContext()
  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)
  const sameDate = startDateMoment.date() === endDateMoment.date()
  const sameMonth = startDateMoment.month() === endDateMoment.month()
  const sameYear = startDateMoment.year() === endDateMoment.year()
  const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
  const days = Math.ceil(timeDiff / (1000 * 3600 * 24));
  const months = Math.floor(days / 30);
  if (sameDate && sameMonth && sameYear) {
    return 'HOURS'
  } else if (days <= 7) {
    return 'DAYS'
  } else if (days <= 31) {
    return 'DATES'
  } else if (months > 1 && months <= 12) {
    return 'MONTHS'
  } else if (months >= 12) {
    return 'YEARS'
  }
}

export const useBringSortedDataFromServer = () => {
  const { setLoading, setSortInfo, setData, startDate, endDate } = useStatisticsContext()
  const timeSortType = GetTheCorrectDateDivide()
  async function bringTheSortedData(type?: "GENDER" | "RISK_LEVEL" | "AGES" | "FILLER", value?: string) {
    setLoading(true)
    if (type && value) {
      setSortInfo(value)
    }
    else{
      setSortInfo("")
    }
    await axios.post("/api/fact-questionnaire/bring-all-the-statistics", { startDate, endDate, timeSortType, type, value })
      .then(data => {
        setData(data.data)
      }).catch((error) => {
        alert(error.message)
      })
    setLoading(false)
  }
  return bringTheSortedData
}



export const useSignInServer = () => {
  const { setLoading, setSortInfo, setData, startDate, endDate, setFirstDateInDb, setEndDate, setStartDate, firstDateInDb } = useStatisticsContext()
  const timeSortType = GetTheCorrectDateDivide()
  async function bringTheDataSignIn() {
    setSortInfo("")
    setLoading(true)
    await axios.get("/api/fact-questionnaire/get-the-first-date-in-table")
      .then(data => {
        const firstDateStr: string = data.data;
        const firstDate: Moment = moment(firstDateStr);
        const newDate: Date = firstDate.toDate();
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(1);
        setFirstDateInDb(newDate)
      }).catch(error => {
        alert(error.message)
      });

    const currentDate = DateToMidnight();
    const firstDateOfYear = firstDateOfTheYear()
    setEndDate(currentDate)
    if (startDate !== firstDateOfYear) {
      if (firstDateOfYear < firstDateInDb) {
        setStartDate(firstDateInDb)
      } else {
        setStartDate(firstDateOfYear)
      }
    }
    await axios.post("/api/fact-questionnaire/bring-all-the-statistics", { startDate, endDate, timeSortType })
      .then((data) => {
        setData(data.data)
      }).catch((error) => {
        alert(error.message)
      })
    setLoading(false)
  }
  return bringTheDataSignIn;

}