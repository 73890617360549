import { GenericMainHeader } from "../generic-components";
import { AnswersStatisticsFirstPart, PositiveAnswersDangerQuestionsSecondPart } from "./statisticsSecondPart";
import '../style/AnswersStatistics.scss'

const AnswersStatistics = () => {
    return (
        <div className="answers-statistics-container">
            <GenericMainHeader text="סטטיסטיקות על השאלות" />
            <AnswersStatisticsFirstPart />
            <PositiveAnswersDangerQuestionsSecondPart />
        </div>
    )
}
export default AnswersStatistics;