import AgesDistributionThirdPart from './AgesDistributionThirdPart'
import FillerInstitutionThirdPart from './FillerInstitutionThirdPart'

import '../../style/GeneralStatistics.scss'

const GeneralStatisticsThirdPart = () => {
    const width = "34vw"
    const height = "35vh"
    return (
        <div className="general-statistics-third-part">
            <AgesDistributionThirdPart width={width} height={height}/>
            <FillerInstitutionThirdPart width={width} height={height}/>
        </div>
    )
}
export default GeneralStatisticsThirdPart