export const GENDERS = {
    male: "זכר",
    female: "נקבה",
    other: "אחר"
}

export const ANSWERS = {
    no: "לא",
    notRelevant: "לא רלוונטי",
    yes: "כן",
    onChecking: "בבדיקה"
}

export const RISK_LEVEL = {
    highRiskLevel: " גבוהה מאוד",
    mediumRiskLevel: " גבוהה",
    lowRiskLevel: " נמוכה-בינונית",
}

export const RISK_LEVEL_TO_HEBREW = {
    " נמוכה-בינונית": " רמת סיכון נמוכה-בינונית",
    " גבוהה": " רמת סיכון גבוהה",
    " גבוהה מאוד": " רמת סיכון גבוהה מאוד"
}

export const NO_DATA = "לא נמצא מידע לטווח תאריכים זה"

export const FILLER = {
    education: "חינוך",
    health: "בריאות",
    welfare: "רווחה",
    police: "משטרה",
    immigrationAndIntegration: "עלייה וקליטה",
    other: "אחר"
}

export const PARENT_SECTION = {
    dangerSituation: "מצבי סכנה מזוהים",
    signsOfInjury: "סממנים לקיומה של פגיעה",
    increaseRisk: "מגבירי סיכון"
}

export const SECTIONS = {
    socialIntegration: "הסתגלות והשתלבות חברתית",
    riskBehaviors: "התנהגויות סיכון",
    learningSkills: "התפתחות ורכישת מיומנויות למידה",
    belongingToAFamily: "השתייכות למשפחה",
    healthAndThePossibilityOfDevelopment: "קיום פיזי, בריאות ואפשרות להתפתחות",
    emotionalHealth: "רווחה ובריאות רגשית",
    identifiedHazardSituations: "מצבי סכנה מזוהים",
    family: "משפחה"
}

export const MONTHS = {
    1: "ינואר",
    2: "פברואר",
    3: "מרץ",
    4: "אפריל",
    5: "מאי",
    6: "יוני",
    7: "יולי",
    8: "אוגוסט",
    9: "ספטמבר",
    10: "אוקטובר",
    11: "נובמבר",
    12: "דצמבר"
}

export const DAYS = {
    Sunday:"ראשון",
    Monday:"שני",
    Tuesday:"שלישי",
    Wednesday:"רביעי",
    Thursday:"חמישי",
    Friday:"שישי",
    Saturday:"שבת"
}