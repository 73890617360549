import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useEffect, useState } from 'react'

import { useStatisticsContext } from '../../common'
import { GenericHeader, GenericTooltip, GenericWhiteSquare } from '../../generic-components'
import NoData from '../NoData'

import '../../style/DatesStatistics.scss'

const xAxisStyle = {
    fontSize: '0.9em',
    fontFamily: 'Heebo-Medium',
    fill: '#43555B',
    marginTop: '2vh'
};
const yAxisStyle = {
    fontSize: '0.86em',
    fill: '#668086',
    fontFamily: 'Heebo-Regular',
    dir: 'rtl'
};
const toolTipStyle = {
    backgroundColor: ' #F5EEEE',
    color: '#43484C',
    borderRadius: '3px',
    fontFamily: 'assistant',
    outline: 'none'
}
const DatesStatisticsFirstPart = () => {
    const { data } = useStatisticsContext()
    const [graphInfo, setGraphInfo] = useState([])
    const width = "74vw"
    const height = "60vh"

    const bringTheCorrectDataArray = () => {
        const graphData = []
        const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        // Convert the dates to milliseconds
        const startDate = new Date(data.startDate).getTime();
        const endDate = new Date(data.endDate).getTime();
        // Calculate the difference in days
        const differenceInDays = Math.round(Math.abs((startDate - endDate) / oneDay));
        for (let i = 0; i < 25; i++) {
            const object = data.questionnaireHoursAverage.find(hour => hour.hour === i) ?? { hour: i, count: 0 }
            graphData.push({
                name: object.hour < 10 ? `0${object.hour}:00` : `${object.hour}:00`,
                count: object.count / differenceInDays
            })
        }
        return graphData
    }
    useEffect(() => {
        if (data.questionnaireHoursAverage !== null) {
            setGraphInfo(bringTheCorrectDataArray)
        }
    }, [data])

    const CustomXAxisTick = ({ x, y, payload }) => {
        const showed = payload.index % 3 === 0 || payload.index === 0 ? true : false;

        return (
            <g transform={`translate(${x},${y + 7})`}>
                {
                    showed && payload.index !== 24 && <text x={0} y={0} textAnchor="middle" fill="#666">
                        <tspan x={0} dy="1em" style={xAxisStyle} >{payload.value}</tspan>
                    </text>
                }
            </g>
        );
    };
    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const obj = payload[0].payload.name !== "24:00" ? payload[0].payload : graphInfo[0]
            return (
                <GenericTooltip height='17vh' width='12vw'>
                    <div>
                        <p className="tooltip-header">{`בשעה: ${obj.name}`}</p>
                        <p className="tooltip-content">{`ממוצע של: ${obj.count.toFixed(Number.isInteger(obj.count) ? 0 : 2)}`}</p>
                    </div>
                </GenericTooltip>
            );
        }
        return null;
    };
    return (
        <div className="dates-statistics-first-part">
            <GenericHeader text='ממוצע שאלונים לפי שעה ביום' />
            <GenericWhiteSquare height={height} width={width}>
                <div>
                    {
                        data.questionnaireHoursAverage === null || graphInfo.length === 0
                            ? <NoData />
                            : <ResponsiveContainer height={'90%'} width={'100%'} minWidth={'74vw'} minHeight={'60vh'}>
                                <AreaChart
                                    width={500}
                                    height={400}
                                    data={graphInfo}
                                    margin={{ top: 39, right: 36, left: 0, bottom: 43 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" tick={CustomXAxisTick} />
                                    <YAxis
                                        tick={yAxisStyle}
                                    />
                                    <Tooltip
                                        contentStyle={toolTipStyle}
                                        content={<CustomTooltip />}
                                        cursor={{ fill: 'transparent' }}
                                    />
                                    <Area type="monotone" dataKey="count" stroke="#88B9CD" fill="#88B9CD33" />
                                </AreaChart>
                            </ ResponsiveContainer >
                    }
                </div>
            </GenericWhiteSquare >
        </div>
    )
}
export default DatesStatisticsFirstPart