import { useEffect, useState } from "react"
import { useStatisticsContext, whiteSquareSizeType } from "../../common"
import { GenericHeader, GenericWhiteSquare } from "../../generic-components"
import NoData from "../NoData"

import '../../style/GeneralStatistics.scss'

const TimeFirstPart = ({ height, width }: whiteSquareSizeType) => {
    const { data } = useStatisticsContext()
    const [text, setText] = useState<string>()
    useEffect(() => {
        if (data.durationAverage !== null) {
            const seconds = Number(data.durationAverage)
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(remainingSeconds).slice(0, 2).padStart(2, '0');;
            setText(`${formattedMinutes}:${formattedSeconds[1] === "." ? `${formattedSeconds[0]}0` : formattedSeconds}`)
        }
    }, [data])

    return (
        <div>
            <GenericHeader text="זמן ממוצע בשאלון" />
            <GenericWhiteSquare
                width={width}
                height={height}
                children={
                    data.durationAverage === null
                        ? <NoData />
                        : <div className="time-container">
                            <div className="info-text">
                                {text}
                            </div>
                            <div className="minutes-text">
                                דקות
                            </div>
                        </div>
                } />
        </div>
    )
}
export default TimeFirstPart