import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider, PrivateRoute, PublicOnlyRoute } from '@hilma/auth';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { StatisticsProvider } from './common';
import {ForgotPasswordPage, ResetPasswordPage, SignInPage,StatisticsPage} from './pages';
import './App.css';

enum CookiesEnum {
  accessTokenCookie = 'kololo',
  twoFactorCookie = 'kloolk'
}
const App = () => (
  <AuthProvider accessTokenCookie={CookiesEnum.accessTokenCookie} twoFactorCookie={CookiesEnum.twoFactorCookie}>
    <LocalizationProvider dateAdapter={AdapterMoment} >
      <StatisticsProvider>
        <Routes>
          <Route path="/" element={<PublicOnlyRoute component={<SignInPage />} componentName='SignInPage' redirectPath='/statistics' />} />
          <Route path="/statistics" element={<PrivateRoute component={<StatisticsPage />} componentName="StatisticsPage" redirectPath="/" />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password-with-email">
            <Route path=":token" element={<ResetPasswordPage />} />
          </Route>
        </Routes>
      </StatisticsProvider>
    </LocalizationProvider>
  </AuthProvider>

);
export default App;

