import { useEffect, useState } from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Direction } from '@mui/system'

import { RISK_LEVEL, useStatisticsContext, BringTheCorrectDataArray } from '../../common'
import { GenericHeader, GenericTooltip, GenericWhiteSquare } from '../../generic-components'
import NoData from '../NoData'

import '../../style/DatesStatistics.scss'

const yAxisStyle = {
    fontSize: '0.8em',
    fill: '#41596B',
    fontFamily: 'Heebo-Medium',
    dir: 'rtl'
};
const bottomLegendStyle = {
    WebkitTextFillColor: 'black',
    direction: 'rtl' as Direction,
    fontSize: '2.4vh',
    padding: '0.5vh',
    width: '60vw',
};
const xAxisStyle = {
    fontSize: '1em',
    fontFamily: 'Heebo-Medium',
    fill: '#41596B',
};
const toolTipStyle = {
    backgroundColor: ' #F5EEEE',
    color: '#43484C',
    borderRadius: '3px',
    fontFamily: 'assistant',
    outline: 'none'
}




const DatesStatisticsSecondPart = () => {
    const [graphInfo, setGraphInfo] = useState([])

    const { data } = useStatisticsContext()
    useEffect(() => {
        if (data.yearEvaluation !== null) {
            setGraphInfo(BringTheCorrectDataArray)
        }
    }, [data])
    const timeSortType = data.timeSortType
    const vh = window.innerHeight / 100;
    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <GenericTooltip height='19vh' width='15vw'>
                    <div>
                        <p className="tooltip-header">{timeSortType === "HOURS" ? "בשעה" : timeSortType === "DAYS" ? "ביום" : timeSortType === "DATES" ? "בתאריך" : timeSortType === "MONTHS" ? "בחודש" : "בשנת"} {payload[0].payload.name}</p>
                        {payload.map((riskLevel, index) => {
                            return <p className="tooltip-content" key={index}>{RISK_LEVEL[riskLevel.dataKey]}: {riskLevel.value}</p>
                        })
                        }
                    </div>
                </GenericTooltip>
            );
        }
        return null;
    };
    const width = "74vw"
    const height = "60vh"


    return (
        <div className="dates-statistics-second-part">
            <GenericHeader text='רמת סיכון ביחס לתקופות ותאריכים' />
            <GenericWhiteSquare height={height} width={width}>
                <div>
                    {
                        data.yearEvaluation === null || graphInfo.length === 0
                            ? <NoData />
                            : <ResponsiveContainer height={'90%'} width={'100%'} minWidth={'74vw'} minHeight={'50vh'}>
                                <BarChart data={graphInfo}>
                                    <CartesianGrid
                                        stroke="#E0E0E0"
                                        strokeWidth={1}
                                        vertical={false}
                                    />
                                    <Legend
                                        iconSize={vh * 1.4}
                                        iconType="square"
                                        wrapperStyle={bottomLegendStyle}
                                        verticalAlign="top"
                                        align="right"
                                        formatter={(value) => RISK_LEVEL[value]}
                                    />
                                    <XAxis dataKey="name"
                                        tick={xAxisStyle}
                                        interval={timeSortType === 'DAYS' || timeSortType === 'MONTHS' || timeSortType === 'YEARS' ? 0 : timeSortType === 'DATES' ? 1 : 2}
                                        tickFormatter={(name) => name.length > 10 ? `${name.substring(0, 10)}...` : name}
                                    />
                                    <YAxis
                                        tickLine={false}
                                        dx={-15}
                                        tick={yAxisStyle}
                                    />
                                    <Tooltip
                                        contentStyle={toolTipStyle}
                                        content={<CustomTooltip />}
                                        cursor={{ fill: 'transparent' }}
                                    />
                                    <Bar dataKey="highRiskLevel" fill="#FF886F" barSize={25} />
                                    <Bar dataKey="mediumRiskLevel" fill="#FFD674" barSize={25} />
                                    <Bar dataKey="lowRiskLevel" fill="#00B4A9" barSize={25} />
                                </BarChart>
                            </ ResponsiveContainer >
                    }
                </div>
            </GenericWhiteSquare >
        </div>
    )
}
export default DatesStatisticsSecondPart