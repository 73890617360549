
import { GeneralStatisticsFirstPart, GeneralStatisticsSecondPart, GeneralStatisticsThirdPart, GeneralStatisticsForthPart } from "./statisticsFirstPart";
import { GenericMainHeader } from "../generic-components";
import { useBringSortedDataFromServer, useStatisticsContext } from "../common";
import '../style/GeneralStatistics.scss'

const GeneralStatistics = () => {
    const {sortInfo} = useStatisticsContext()
    const bringTheSortedData = useBringSortedDataFromServer()

    const cancelTheSort =()=>{
        bringTheSortedData()
    }
    return (
        <div className="general-statistics-container">
            <div className="header">
                <GenericMainHeader text="כללי" />
                {
                    sortInfo !== ""
                    && 
                    <div className="sorted-info-container">
                        סינון לפי:
                        <div className="sorted-info" onClick={cancelTheSort}>
                        {sortInfo}
                        <img alt='close' className='close' src='/close.svg' />
                    </div>
                    </div>
                }
            </div>
            <GeneralStatisticsFirstPart />
            <GeneralStatisticsSecondPart />
            <GeneralStatisticsThirdPart />
            <GeneralStatisticsForthPart />
        </div>
    )
}
export default GeneralStatistics;