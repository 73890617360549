export const DateToMidnight = (date?: string) => {
    const newDate = date ? new Date(date) : new Date();
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59);
    newDate.setMilliseconds(999);
    return newDate
}

export const firstDateOfTheYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const firstDateOfYear = new Date(currentYear, 0, 1);
    return firstDateOfYear

}