import { useStatisticsContext } from '../../common'
import TheMostAnswersFirstPart from './TheMostAnswersFirstPart'

import '../../style/AnswersStatistics.scss'

const AnswersStatisticsFirstPart = () => {
    const {data} = useStatisticsContext()
    const width = "34vw"
    const height = "60vh"
    return (
        <div className="answers-statistics-first-part">
            <TheMostAnswersFirstPart color='#00627D' data={data.theMostYesAnswers} header='השאלות שקיבלו הכי הרבה תשובות חיוביות' height={height} width={width}/>
            <TheMostAnswersFirstPart color='#0088AF' data={data.theMostNoAnswers} header='השאלות שקיבלו הכי הרבה תשובות שליליות' height={height} width={width}/>
        </div>
    )
}
export default AnswersStatisticsFirstPart