import { FILLER, useStatisticsContext, whiteSquareSizeType } from '../../common';
import { GenericHeader, GenericPieChart, GenericWhiteSquare, } from '../../generic-components';
import NoData from '../NoData';

import '../../style/GeneralStatistics.scss';

const FillerInstitutionThirdPart = ({ height, width }: whiteSquareSizeType) => {
    const { data } = useStatisticsContext();
    const COLORS = [
        "#00627D", "#0088AF","#00B4A9", "#66DD8B","#FFD674", "#FF886F",  
    ]
    const pieData = data.fillerInstitution !== null && data.fillerInstitution.map((filler, index) => {
        return {
            name: FILLER[filler.name],
            value: filler.count,
            color: COLORS[index],
            fillerProf: filler.fillerProfession
        }
    })
    const CustomTooltip = (fillerProf: { name: string, count: number }[], percentValue: number, name: string): JSX.Element => {
        return (
            <div className="filler_custom-tooltip">
                <GenericHeader text={`${percentValue}% מקצועות ${name} מתוכם:`} />
                <div className='filler-prof-container'>
                    {
                        fillerProf.map((filler) => {
                            return (
                                <div className='filler-name-label'>
                                    <div className='filler-count'>{filler.count}</div>
                                    {filler.name}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    };

    return (
        <div>
            <GenericHeader text="מקצועות ממלא השאלון" />
            <GenericWhiteSquare width={width} height={height}>
                {data.fillerInstitution === null
                    ? <NoData />
                    : <GenericPieChart bottom={false} hole pieData={pieData} tooltipContent='sdfgh' label notDefaultTooltip={CustomTooltip} onClickType='FILLER' />
                }
            </GenericWhiteSquare>
        </div>
    );
};

export default FillerInstitutionThirdPart;
