import AnswersDistributionSecondPart from "./AnswersDistributionSecondPart"
import GendersDistributionSecondPart from "./GendersDistributionSecondPart"
import RiskLevelDistributionSecondPart from "./RiskLevelDistributionSecondPart"

import '../../style/GeneralStatistics.scss'

const GeneralStatisticsSecondPart = () => {
    const width = "20.6vw"
    const height = "35vh"
    return (
        <div className="general-statistics-second-part">
            <AnswersDistributionSecondPart width={width} height={height}/>
            <GendersDistributionSecondPart width={width} height={height}/>
            <RiskLevelDistributionSecondPart width={width} height={height}/>

        </div>
    )
}
export default GeneralStatisticsSecondPart