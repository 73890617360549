import StatisticsDataType from "../types/statisticsData.type";

export const DataInitialization: StatisticsDataType = {
    age: [{ value: 1, range: "", count: 0 }],
    allTheUsersThatFillMoreThanOneReport: 0,
    answersDistribution: [{ value: "", count: 0 }],
    durationAverage: "",
    evaluation: [{ riskLevel: "", count: 0 }],
    fillerInstitution: [{ name: "", count: 0, fillerProfession: [{ name: "", count: 0 }] }],
    gender: [{ name: "", count: 0 }],
    sectionsStatistics: {
        highRiskStatistics: [{ text: "", number: 0 }],
        parentSectionStatistics: [{ name: "", average: 0 }],
        sectionStatistics: [{ name: "", average: 0 }]
    },
    theLengthOfAllTheReportsOfAnYear: 40,
    theMostNoAnswers: [{ text: "", count: 0 }],
    theMostYesAnswers: [{ text: "", count: 0 }],
    unfinishedPeoples: 0,
    yearEvaluation: [{ riskLevel: "", count: [{ month: 0, count: "" }] }],
    questionnaireHoursAverage: [{ hour: 0, count: 0 }],
    startDate: new Date(),
    endDate: new Date(),
    timeSortType: "HOURS"
};