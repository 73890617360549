import AverageScoreParentSectionForthPart from './AverageScoreParentSectionForthPart'
import AverageScoreSectionForthPart from './AverageScoreSectionForthPart'

import '../../style/GeneralStatistics.scss'

const GeneralStatisticsForthPart = () => {
    const width = "20vw"
    const height = "40vh"
    return (
        <div className="general-statistics-forth-part">
            <AverageScoreParentSectionForthPart width={width} height={height}/>
            <AverageScoreSectionForthPart width={"48vw"} height={height}/>
        </div>
    )
}
export default GeneralStatisticsForthPart