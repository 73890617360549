import { GenericMainHeader } from "../generic-components";
import { DatesStatisticsFirstPart, DatesStatisticsSecondPart } from "./statisticsThirdPart";
import '../style/GeneralStatistics.scss'

const DatesStatistics = () => {
    return (
        <div className="dates-statistics-container">
            <GenericMainHeader text="סטטיסטיקות על תאריכים" />
            <DatesStatisticsFirstPart/>
            <DatesStatisticsSecondPart/>
        </div>
    )
}
export default DatesStatistics;