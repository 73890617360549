import { GENDERS, useStatisticsContext, whiteSquareSizeType } from '../../common';
import { GenericHeader, GenericPieChart,GenericWhiteSquare } from '../../generic-components';
import NoData from '../NoData';

import '../../style/GeneralStatistics.scss';

const GendersDistributionSecondPart = ({ height, width }: whiteSquareSizeType) => {
    const { data } = useStatisticsContext();
    const COLORS = [ "#00627D","#00B4A9","#FFD674"]
    const pieData = data.gender !== null && data.gender.map((answer, index) => {
        if (answer.count !== 0) {
            return {
                name: GENDERS[answer.name],
                value: answer.count,
                color: COLORS[index]
            }
        }
        else {
            return {
                name: "",
                value: 0,
                color: "white"
            }
        }
    })

    return (
        <div>
            <GenericHeader text="התפלגות מגדר" />
            <GenericWhiteSquare width={width} height={height}>
                {data.gender === null
                    ? <NoData />
                    : pieData.length === 0
                        ? <NoData />
                        : <GenericPieChart pieData={pieData} hole={false} bottom tooltipContent={'המשתתפים'} onClickType={'GENDER'} label/>}
            </GenericWhiteSquare>
        </div>
    );
};

export default GendersDistributionSecondPart;
