import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import {
    FormProvider,
    FormSubmitButton,
} from "@hilma/forms";
import * as yup from 'yup';
import Alert from '@mui/material/Alert/Alert';
import { Grow } from '@mui/material';

import { TextInput } from '../components';

import '../style/SignInPage.scss'

const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const [openAlert, setOpenAlert] = useState(false)
    const location = useLocation();
    const inputSchema = yup.string().required('שדה זה הינו חובה').min(8, 'הסיסמה צריכה להכיל לפחות 8 תווים').matches(/.*\d.*/, 'הסיסמה צריכה להכיל לפחות מספר אחד')

    const token = useMemo(() => {
        return location.pathname.split('/')[2];
    }, [location]);

    let formSchema = yup.object({
        newPassword: inputSchema,
        validateNewPassword: inputSchema.oneOf([yup.ref('newPassword'), null], 'הסיסמה אינה תואמת'),
    });

    const changePassword = async (newPassword: string, formBag) => {
        try {
            const res = await axios.post('/api/admin/reset-password-with-email', { newPassword, token })
            if (res.data) {
                setOpenAlert(true);
                setTimeout(() => navigate("/"), 3000);
            }else {
                formBag.setFieldError('newPassword', 'חלה תקלה בהחלפת הסיסמה')
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <div className='sign-in-page-background-image'>
            <div className='sign-in-form-container'>
                <div className='header'>
                    שינוי סיסמה
                </div>
                <FormProvider
                    initialValues={{
                        newPassword: "",
                        validateNewPassword: ""
                    }}
                    dir="ltr"
                    onSubmit={(value, formBag) => { changePassword(value.newPassword, formBag); }}
                    validationSchema={formSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    <TextInput isPassword label='סיסמה חדשה' name={'newPassword'} eyeHiddenSrc="../eyeHidden.svg" showPasswordSrc='../showPassword.svg' />
                    <TextInput isPassword label='אימות סיסמה חדשה' name={'validateNewPassword'} eyeHiddenSrc="../eyeHidden.svg" showPasswordSrc='../showPassword.svg' />
                    <FormSubmitButton className="">אישור</FormSubmitButton>
                </FormProvider>
                <Grow className='success-alert' in={openAlert}>
                    <Alert onClose={() => setOpenAlert(false)} severity="success">!הסיסמה שונתה בהצלחה</Alert>
                </Grow>
            </div>
            <div className='image-container'>
                <img alt='logo' className='logo-sign-in-page' src='/icon.png' />
                <div className='welcome'>ברוכים הבאים!</div>
            </div>
        </div >
    )
}

export default ResetPasswordPage
