import { ANSWERS, useStatisticsContext, whiteSquareSizeType } from '../../common';
import { GenericHeader, GenericPieChart } from '../../generic-components';
import GenericWhiteSquare from '../../generic-components/WhiteSquare';
import NoData from '../NoData';

import '../../style/GeneralStatistics.scss';

const AnswersDistributionSecondPart = ({ height, width }: whiteSquareSizeType) => {
    const { data } = useStatisticsContext();
    const COLORS = [ "#FF886F","#FFD674","#00627D","#00B4A9",]
    const pieData = data.answersDistribution !== null && data.answersDistribution.map((answer, index) => {
        return {
            name: ANSWERS[answer.value],
            value: answer.count,
            color: COLORS[index]
        }
    })
    const noDataTest = data.answersDistribution !== null && pieData.every(data => data.value === 0)
    return (
        <div>
            <GenericHeader text="התפלגות תשובות" />
            <GenericWhiteSquare width={width} height={height}>

                {data.answersDistribution === null || noDataTest
                    ? <NoData />
                    : <GenericPieChart pieData={pieData} hole bottom tooltipContent={'התשובות'} label />}
            </GenericWhiteSquare>
        </div>
    );
};

export default AnswersDistributionSecondPart;
