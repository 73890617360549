import { DatePicker } from "@mui/x-date-pickers";
import '../style/DatePicker.scss'
import React, { useState } from "react";
import moment, { Moment } from 'moment';
import Button from '@mui/material/Button';
import { DateToMidnight, useBringSortedDataFromServer, useStatisticsContext } from "../common";

const DATE_PICKER_STYLE = {
    width: '8vw',
    height: '4vh',
    borderRadius: '5px',
    marginLeft: '0.2vw'
}

const DateRange = () => {
    const { startDate, setStartDate, endDate, setEndDate, firstDateInDb } = useStatisticsContext()
    const [openFirstDate, setOpenFirstDate] = useState(false); // time picker popup open state
    const [openEndDate, setOpenEndDate] = useState(false); // time picker popup open state
    const bringTheSortedData = useBringSortedDataFromServer()
    const filterAndBringTheInfo = async () => {
        if (startDate > endDate) {
            const start = endDate;
            const end = startDate
            setStartDate(start)
            setEndDate(end)
            bringTheSortedData()
        }
        else {
            bringTheSortedData()
        }
    }

    return (
        <div>
            <div className='date-picker-container'>
                <div className="input-container">
                    <div className="input-title">מ:</div>
                    <DatePicker
                        slotProps={{ textField: { className: 'text-field-date' } }}
                        views={['year', 'month', 'day']}
                        // openTo="month"
                        sx={DATE_PICKER_STYLE}
                        minDate={moment(firstDateInDb)}
                        open={openFirstDate}
                        onOpen={() => setOpenFirstDate(true)}
                        onClose={() => setOpenFirstDate(false)}
                        format="DD/MM/YYYY"
                        disableFuture
                        desktopModeMediaQuery='@media (min-width: 768px)' // for responsive design
                        value={moment(startDate)} // the date picker value
                        onChange={(newValue: Moment) => { // change the current value (date)
                            const date = newValue.format('YYYY-MM-DD');
                            const newDate = new Date(date)
                            newDate.setHours(0);
                            newDate.setMinutes(0);
                            newDate.setSeconds(0);
                            newDate.setMilliseconds(1);
                            setStartDate(newDate);
                        }}
                    />
                </div>
                <div className="input-container">
                    <div className="input-title">עד ל:</div>
                    <DatePicker
                        slotProps={{ textField: { className: 'text-field-date' } }}
                        views={['year', 'month', 'day']}
                        // openTo="month"
                        minDate={moment(firstDateInDb)}
                        open={openEndDate}
                        onOpen={() => setOpenEndDate(true)}
                        onClose={() => setOpenEndDate(false)}
                        format="DD/MM/YYYY"
                        disableFuture
                        sx={DATE_PICKER_STYLE}
                        desktopModeMediaQuery='@media (min-width: 768px)' // for responsive design
                        value={moment(endDate)} // the date picker value
                        onChange={(newValue: Moment) => { // change the current value (date)
                            const date = newValue.format('YYYY-MM-DD');
                            const newDate = DateToMidnight(date)
                            setEndDate(newDate);
                        }}
                    />
                </div>
                <Button className='filter-button' onClick={filterAndBringTheInfo}>סינון</Button>
            </div>
        </div>
    )
}

export default DateRange;