import '../style/GenericHeaders.scss'

interface headerProps {
    text:string
}

export const GenericMainHeader=({text}:headerProps)=>{
    return(
        <div className="main-header">
            {text}
        </div>
    )
}

export const GenericHeader=({text}:headerProps)=>{
    return(
        <div className="little-header">
            {text}
        </div>
    )
}