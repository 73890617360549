import { Bar, BarChart, Cell, LabelProps, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import {  useBringSortedDataFromServer, useStatisticsContext, whiteSquareSizeType } from '../../common';
import { GenericHeader, GenericWhiteSquare } from '../../generic-components';
import NoData from '../NoData';

import '../../style/GeneralStatistics.scss';

const AgesDistributionThirdPart = ({ height, width }: whiteSquareSizeType) => {
    const { data } = useStatisticsContext();
    const bringTheSortedData = useBringSortedDataFromServer()
    const COLORS = ["#0088AF", "#00B4A9", "#FF886F"]
    const barData = data.age !== null && data.age.map((range, index) => {
        return {
            number: range.value,
            name: range.range,
            value: range.count,
            color: COLORS[index]
        }
    })

    const renderCustomizedLabel = (props: LabelProps) => {
        let total = 0;
        for (let i = 0; i < barData.length; i++) {
            total += barData[i].value
        }
        const { value, y } = props
        const vh = window.innerHeight / 100
        const vw = window.innerWidth / 100
        const percentValue = Math.round((Number(value) / total) * 100)
        return (
            <text fill={"#43484C"} opacity={"100%"} fontSize={"2.5vh"}
                x={36 * vw} y={Number(y) + 4 * vh} textAnchor='end'
                className='present-text'
            >
                {`${percentValue}%`}
            </text>
        );
    };
    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            let total = 0;
            for (let i = 0; i < barData.length; i++) {
                total += barData[i].value
            }
            return (
                <div className="custom-tooltip">
                    <h1 className="tooltip-title">בגילאים: {payload[0].payload.name}</h1>
                    <p className="tooltip-content">{`${payload[0].payload.value} מתוך ${total}`} ילדים</p>
                </div>
            );
        }
    }
    return (
        <div>
            <GenericHeader text="התפלגות גילאים" />
            <GenericWhiteSquare width={width} height={height}>
                {data.age === null
                    ? <NoData />
                    : <ResponsiveContainer
                        className={'responsive-container-horizontal-graph'}
                        height={'85%'} width={'120%'}
                    >
                        <BarChart
                            data={barData.sort((a, b) => a.number - b.number)}
                            layout="vertical"
                            margin={{ right: 65 }}
                            style={{ direction: "ltr" }}
                            onClick={(event) => {
                                bringTheSortedData("AGES", event.activeLabel)
                            }}
                            className='cursor-pointer'
                        >
                            <YAxis type="category" dataKey="name" width={100} height={50}
                                orientation='left' stroke='transparent'
                                tick={{ fill: '#00638A', fontSize: '3vh', fontFamily: 'Heebo-medium' }}
                            />
                            <XAxis type="number" domain={[0, (max: number) => {
                                return max
                            }]} hide />
                            <Tooltip
                                allowEscapeViewBox={{ x: true, y: true }}
                                wrapperStyle={{ outline: 'none' }}
                                cursor={{ fill: 'transparent' }}
                                content={
                                    <CustomTooltip />
                                }
                            />
                            <Bar
                                dataKey="value"
                                label={renderCustomizedLabel}
                                barSize={window.innerHeight * 0.07}
                                isAnimationActive={false}
                            >
                                {barData.map((_, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>

                }
            </GenericWhiteSquare>
        </div>
    );
};

export default AgesDistributionThirdPart;
