import { createContext, FC, ReactNode, useContext, useState } from "react";
import { StatisticsDataType } from "../types";
import { DataInitialization } from "../consts";
import { DateToMidnight, firstDateOfTheYear } from "../functions";
import LoadingScreen from "../../components/LoadingScreen";

interface StatisticsType {
  startDate: Date;
  setStartDate: (startDate: Date) => void;
  endDate: Date;
  setEndDate: (endDate: Date) => void;
  firstDateInDb: Date;
  data: StatisticsDataType;
  setData: (data: StatisticsDataType) => void;
  loading: boolean;
  setSortInfo: (value: string) => void;
  sortInfo: string,
  setLoading: (loading: boolean) => void;
  setFirstDateInDb: (date: Date) => void;
}

export const StatisticsContext = createContext<StatisticsType>({} as StatisticsType);
export const StatisticsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [startDate, setStartDate] = useState<Date>(firstDateOfTheYear())
  const [endDate, setEndDate] = useState<Date>(DateToMidnight())
  const [firstDateInDb, setFirstDateInDb] = useState<Date>()
  const [data, setData] = useState<StatisticsDataType>(DataInitialization)
  const [loading, setLoading] = useState<boolean>(false)
  const [sortInfo, setSortInfo] = useState<string>('')

  return (
    <StatisticsContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        firstDateInDb,
        data,
        setData,
        loading,
        sortInfo,
        setLoading,
        setSortInfo,
        setFirstDateInDb
      }}>
      {loading && <LoadingScreen />}
      {children}
    </StatisticsContext.Provider>
  );

}

export const useStatisticsContext = () => useContext(StatisticsContext)