import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { FormProvider, FormSubmitButton } from "@hilma/forms";
import * as yup from 'yup';
import Alert from '@mui/material/Alert/Alert';
import { Grow } from '@mui/material';

import TextInput from '../components/TextInput';

import '../style/SignInPage.scss'

const ForgotPasswordPage = () => {

    const navigate = useNavigate();
    const [openAlert, setOpenAlert] = useState(false)

    const sendEmail = async (values, formBag) => {
        const { email } = values;
        try {
            const res = await axios.post('/api/admin/forgot-password', { email })
            if (res.data === "user doesn't exist") {
                formBag.setFieldError('email', 'כתובת המייל אינה קיימת במערכת')
            }
            else {
                setOpenAlert(true)
                setTimeout(() => navigate("/"), 3000)
            }
        } catch (error) {
            console.log('error: ', error);
        }
    }

    let formSchema = yup.object({
        email: yup.string().email("כתובת מייל לא תקין").required('שדה זה הינו חובה'),
        validateEmail: yup.string().email("כתובת מייל לא תקין").required('שדה זה הינו חובה').oneOf([yup.ref('email'), null], 'כתובת המייל אינה תואמת')
    });


    return (
        <div className='sign-in-page-background-image'>
            <div className='forgot-password-form-container'>
                <div className='header'>
                    שחזור סיסמה
                </div>
                <FormProvider
                    initialValues={{
                        email: "",
                        validateEmail: ""
                    }}
                    dir="ltr"
                    onSubmit={(values, formBag) => { sendEmail(values, formBag); }}
                    validationSchema={formSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    <TextInput label='מייל' name={'email'} />
                    <TextInput label='אימות כתובת מייל' name={'validateEmail'} />
                    <FormSubmitButton >שליחה</FormSubmitButton>
                </FormProvider>
                <div className='go-back-container'>
                    <button className='go-back' onClick={() => navigate('/')}>חזרה</button>
                </div>
                <Grow className='success-alert' in={openAlert}>
                    <Alert onClose={() => setOpenAlert(false)} severity="success">!המייל לאיפוס הסיסמה נשלח בהצלחה</Alert>
                </Grow>
            </div>
            <div className='image-container'>
                <img alt='logo' className='logo-sign-in-page' src='/goodicon.png' />
                <div className='welcome'>ברוכים הבאים!</div>
            </div>
        </div >
    )
}

export default ForgotPasswordPage;