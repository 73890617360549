import { whiteSquareSizeType, useStatisticsContext } from '../../common'
import { GenericHeader, GenericWhiteSquare } from '../../generic-components'
import NoData from '../NoData'

import '../../style/GeneralStatistics.scss'

const QuestionnaireLengthFirstPart = ({ height, width }: whiteSquareSizeType) => {
    const { data } = useStatisticsContext()
    return (
        <div>
            <GenericHeader text="מספר השאלונים שנענו" />
            <GenericWhiteSquare
                width={width}
                height={height}
                children={
                    data.theLengthOfAllTheReportsOfAnYear === null
                        ? <NoData />
                        : <div className="info-text">
                            {data.theLengthOfAllTheReportsOfAnYear}
                        </div>
                }
            />
        </div>
    )
}
export default QuestionnaireLengthFirstPart