import { useState } from 'react';
import { useLogin } from '@hilma/auth'
import { FormProvider, FormSubmitButton } from '@hilma/forms'
import { useNavigate } from 'react-router-dom'
import '../style/SignInPage.scss'
import * as yup from "yup";
import TextInput from '../components/TextInput';
import { useSignInServer } from '../common';
const formSchema = yup.object({
    username: yup.string().required("שדה זה הינו חובה.").email("מייל לא תקין"),
    password: yup.string().required("שדה זה הינו חובה.")
});

const SignInPage = () => {
    const bringTheDataSignIn = useSignInServer()
    const login = useLogin()
    const navigate = useNavigate();

    const [userNotFound, setUserNotFound] = useState<boolean>(false)

    const tryToLogIn = async (values: { username: string, password: string }) => {
        const { username, password } = values
        const res = await login("/api/admin/login", { username, password })
        if (!res.success) {
            setUserNotFound(true)
        }
        else{
            bringTheDataSignIn()
        }
    }

    return (
        <div className='sign-in-page-background-image'>
            <div className='sign-in-form-container'>
                <div className='header'>
                    התחברות
                </div>
                <FormProvider
                    initialValues={{
                        username: "",
                        password: "",
                    }}
                    onSubmit={tryToLogIn}
                    validationSchema={formSchema}
                    validateOnChange={false}
                >
                    <TextInput label='מייל' name={'username'} onClick={() => setUserNotFound(false)} />
                    <TextInput isPassword label='סיסמה' name={'password'} onClick={() => setUserNotFound(false)} />

                    <FormSubmitButton>כניסה</FormSubmitButton>
                </FormProvider>
                <div className='container-under-the-button' style={{ justifyContent: !userNotFound && "flex-end" }}>
                    {userNotFound && <h3 className='user-not-found'>משתמש לא נמצא</h3>}
                    <button className='forget-password' onClick={() => navigate('/forgot-password')}>שכחת סיסמה?</button>
                </div>
            </div>
            <div className='image-container'>
                <img alt='logo' className='logo-sign-in-page' src='/goodicon.png' />
                <div className='welcome'>ברוכים הבאים!</div>
            </div>
        </div >
    )
}
export default SignInPage