import { Direction } from "@material-ui/core";
import { Tooltip, Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts"

import GenericTooltip from "./GenericTooltip";
import { RISK_LEVEL_TO_HEBREW, useBringSortedDataFromServer } from "../common";

import '../style/GenericPieChart.scss'

interface pieChartProps {
    //the data to show on the pie chart
    pieData: { name: string, value: number, color: string }[];
    //decides if the ie have hole or not
    hole: boolean;
    //decides if the label is on the bottom
    bottom: boolean;
    //the tooltip content
    tooltipContent: string;
    //decides if the there is a label, or lines that going out of the chart(if false/undefined, so lines)
    label?: boolean,
    onClickType?: "GENDER" | "RISK_LEVEL" | "AGES" | "FILLER";
    notDefaultTooltip?: (fillerProf: { name: string, count: number }[], percentValue: number, name: string) => JSX.Element
}

const GenericPieChart = ({ pieData, hole, bottom, tooltipContent, label, notDefaultTooltip, onClickType }: pieChartProps) => {
    const bringTheSortedData = useBringSortedDataFromServer()
    const vh = window.innerHeight / 100;
    const legendStyle = {
        WebkitTextFillColor: 'black',
        direction: 'rtl' as Direction,
        left: '8%',
        fontSize: '2.3vh',
        padding: '0.5vh',
        paddingRight: '1vw',
        marginRight: '3vw',
    };

    const bottomLegendStyle = {
        WebkitTextFillColor: 'black',
        direction: 'rtl' as Direction,
        left: '15%',
        fontSize: '2.4vh',
        padding: '0.5vh',
        marginRight: '3vw',
        width: '7vw',
        marginBottom: '1vh'
    };

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            let total = 0;
            for (let i = 0; i < pieData.length; i++) {
                total += pieData[i].value
            }
            const percentValue = Math.round((payload[0].value / total) * 100)
            return (
                <div>
                    {
                        notDefaultTooltip
                            ? notDefaultTooltip(payload[0].payload.fillerProf, percentValue, payload[0].name)
                            : <GenericTooltip>
                                <div className="custom-tooltip">
                                    <h1 className="tooltip-title">{payload[0].name}</h1>
                                    <p className="tooltip-percentage">{percentValue}%</p>
                                    <p className="tooltip-content">{`${payload[0].value.toFixed(Number.isInteger(payload[0].value) ? 0 : 2)} מתוך ${total.toFixed(Number.isInteger(total) ? 0 : 2)} ${tooltipContent}`}</p>
                                </div>
                            </GenericTooltip>
                    }
                </div>
            );
        }
    }

    const onClickFunction = (info) => {
        if (onClickType) {
            if (onClickType === "RISK_LEVEL") {
                bringTheSortedData(onClickType, RISK_LEVEL_TO_HEBREW[info.name])
            } else {
                bringTheSortedData(onClickType, info.name)
            }
        }
    }

    return (
        <ResponsiveContainer width="100%" height="100%" className={!bottom ? 'bottom ' : 'generic-pie-chart'}>
            <PieChart width={300} height={300}>
                <Pie
                    className={onClickType && "cursor-pointer"}
                    label={label
                        ? false
                        : ({
                            cx,
                            cy,
                            midAngle,
                            innerRadius,
                            outerRadius,
                            value,
                            index
                        }) => {
                            const RADIAN = Math.PI / 180;
                            const radius = 25 + innerRadius + (outerRadius - innerRadius);
                            const x = cx + radius * Math.cos(-midAngle * RADIAN);
                            const y = cy + radius * Math.sin(-midAngle * RADIAN);
                            return (
                                <text
                                    x={x}
                                    y={y}
                                    fill="black"
                                    textAnchor={x > cx ? "start" : "end"}
                                    dominantBaseline="central"
                                    className="label-text"
                                >
                                    {pieData[index].name}
                                </text>
                            )
                        }}

                    onClick={onClickFunction}
                    dataKey="value"
                    data={pieData}
                    innerRadius={hole ? "60%" : 0}
                    outerRadius={"90%"}
                    stroke="none"
                >
                    {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} style={{ outline: "none" }} />
                    ))}
                </Pie>
                <Tooltip
                    allowEscapeViewBox={{ x: true, y: true }}
                    wrapperStyle={{ outline: 'none' }}
                    content={
                        <CustomTooltip />
                    }
                />
                {label && bottom
                    ? <Legend
                        iconSize={vh * 1.4}
                        iconType="square"
                        wrapperStyle={legendStyle}
                    />
                    : label && !bottom &&
                    <Legend
                        iconSize={vh * 1.4}
                        iconType="square"
                        wrapperStyle={bottomLegendStyle}
                        verticalAlign="middle"
                        align="left"
                    />
                }
            </PieChart>
        </ResponsiveContainer>
    )
}
export default GenericPieChart