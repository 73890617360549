import { useState } from 'react';
import { useLogout } from '@hilma/auth';

import { Fade, MenuItem, Menu, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import { ChangePasswordPage } from '../pages';
import DateRange from './DateRange';

import '../style/HomeHeader.scss'

const HomeHeader = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(false)
    const logout = useLogout()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className="header-container">
            <div>
                <ChangePasswordPage isOpen={isOpen} setIsOpen={setIsOpen} />
                <Button
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <AccountCircleIcon
                        sx={{ color: '#177195', fontSize: '6vh', opacity: 0.7 }}
                    />
                </Button>
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <MenuItem onClick={() => { setIsOpen(true) }} sx={{ direction: 'rtl' }}>
                        <VpnKeyIcon
                            sx={{ color: '#177195', fontSize: '3vh', opacity: 0.5, marginLeft: '0.7vw' }}
                        />
                        שינוי סיסמה
                    </MenuItem>
                    <MenuItem onClick={() => { logout() }} sx={{ direction: 'rtl' }}>
                        <LogoutIcon
                            sx={{ color: '#177195', fontSize: '3vh', opacity: 0.5, marginLeft: '0.7vw' }}
                        />
                        התנתקות
                    </MenuItem>
                </Menu>
            </div>
            <div className='logo-container'>
                <h1 className='header-text'>סטטיסטיקות שאלון איתותים</h1>
                <img alt='logo' className='logo-sign-in-page' src='/icon.png' />
            </div>

            <DateRange />

        </div>
    )
}
export default HomeHeader