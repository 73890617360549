import { GenericHeader, GenericWhiteSquare } from '../../generic-components';
import NoData from '../NoData';

import '../../style/AnswersStatistics.scss'

interface TheMostAnswersFirstPartProps {
    color: string;
    header: string;
    data: { text: string, count: number }[];
    width: string;
    height: string;
}
const TheMostAnswersFirstPart = ({ color, header, data, height, width }: TheMostAnswersFirstPartProps) => {
    const orderData = data !== null && data.sort((a, b) => b.count - a.count)
    return (
        <div >
            <GenericHeader text={header} />
            <GenericWhiteSquare 
                containerStyle={{
                    height:'auto',
                    width:'auto',
                    padding:0,
                    borderRadius:0
                }}
            >
                <div className='the-most-answers-container custom-scroll-bar'>
                    {
                        data === null || orderData.length === 0
                            ? <NoData />
                            : orderData.map((answer, index) => {
                                return (
                                    <div className='the-most-answers-data' key={index}>
                                        <div style={{ backgroundColor: color }} className='the-most-answer-number'>{index + 1}</div>
                                        <div className='the-most-answer-text'>{answer.text}</div>
                                    </div>
                                )
                            })
                    }
                </div>
            </GenericWhiteSquare >
        </div >
    )

}
export default TheMostAnswersFirstPart;