import { NO_DATA } from "../common"

const NoData = () => {
    return (
        <div style={{
            color: "#448EAB",
            fontSize: "3.5vh",
            direction: "rtl",
            fontFamily: "Heebo-medium",
            textAlign: "center"
        }}>
            {NO_DATA}
        </div>
    )
}
export default NoData