import { whiteSquareSizeType, useStatisticsContext } from "../../common"
import { GenericHeader, GenericWhiteSquare } from "../../generic-components"
import NoData from "../NoData"

import '../../style/GeneralStatistics.scss'

const ReturnUsersPart = ({ height, width }: whiteSquareSizeType) => {
    const { data } = useStatisticsContext()
    const allPresent = (data.allTheUsersThatFillMoreThanOneReport / data.theLengthOfAllTheReportsOfAnYear) * 100
    const present = JSON.stringify(allPresent).slice(0, allPresent < 10 ? 3 : 4)
    const text = `${present}%`
    return (
        <div>
            <GenericHeader text="אחוז משתמשים חוזרים" />
            <GenericWhiteSquare
                width={width}
                height={height}
                children={
                    data.allTheUsersThatFillMoreThanOneReport === null
                        ? <NoData />
                        : <div className="info-text">
                            {text}
                        </div>
                } />
        </div>
    )
}
export default ReturnUsersPart