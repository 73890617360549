import React, { CSSProperties } from "react";

import '../style/WhiteSquare.scss'

interface WhiteSquareProps {
    width?: string;
    height?: string;
    children: React.ReactNode,
    containerClassName?: string,
    containerStyle?: CSSProperties;
}

const GenericWhiteSquare = ({ width, height, children, containerClassName, containerStyle }: WhiteSquareProps) => {
    return (
        <div className={`generic-white-square ${containerClassName}`} style={
            {
                width: width,
                height: height,
                ...containerStyle
            }
        }
        >
            {children}
        </div>
    )

}
export default GenericWhiteSquare;