import '../style/GenericTooltip.scss'

interface GenericTooltipProps {
    width?: string,
    height?: string,
    children: React.ReactNode
}

const GenericTooltip = ({ width, height, children }: GenericTooltipProps) => {
    return (
        <div className="generic-tooltip" style={{ width, height }}>
            {children}
        </div>
    )
}
export default GenericTooltip