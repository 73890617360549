import moment from "moment";
import { DAYS, MONTHS, useStatisticsContext } from '..'


const BringTheCorrectDataArray = () => {
    const { data } = useStatisticsContext()
    let graphData = []
    const startDate = moment(data.startDate)
    const endDate = moment(data.endDate)
    const timeSortType = data.timeSortType
    const allDatesArray = [];
    if (timeSortType === 'DATES' || timeSortType === 'DAYS') {
        let currentDate = startDate;

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
            allDatesArray.push(currentDate.format("YYYY-MM-DD"));
            currentDate.add(1, "day");
        }
    }
    let minNumber = 0
    let maxNumber = 0
    if (timeSortType === 'YEARS') {
        minNumber = 0
        maxNumber = endDate.year() - startDate.year()
    } else if (timeSortType === 'MONTHS') {
        minNumber = startDate.month() + 1
        maxNumber = endDate.month() + 1
    } else if (timeSortType === 'DATES') {
        minNumber = 0
        maxNumber = allDatesArray.length - 1
    } else if (timeSortType === 'DAYS') {
        minNumber = 0
        maxNumber = allDatesArray.length - 1
    } else if (timeSortType === 'HOURS') {
        minNumber = 0
        maxNumber = 24
    }

    for (let i = minNumber; i <= maxNumber; i++) {
        const monthNumber = timeSortType === "YEARS" ? startDate.year() + i : timeSortType === "DAYS" ? (Number(moment(allDatesArray[i]).format('d')) + 1) : timeSortType === 'DATES' ? moment(allDatesArray[i]).date() : i
        const dateName =
            timeSortType === "YEARS"
                ? startDate.year() + i
                : timeSortType === "MONTHS"
                    ? `${MONTHS[i]} ${startDate.year()}`
                    : timeSortType === "DATES"
                        ? moment(allDatesArray[i]).format('DD/MM')
                        : timeSortType === "DAYS"
                            ? `${DAYS[moment().day(monthNumber - 1).format("dddd")]}`
                            : `${i < 10 ? `0${i}` : i}:00`
        graphData.push({ monthNumber, name: dateName, lowRiskLevel: 0, mediumRiskLevel: 0, highRiskLevel: 0 })
    }
    data.yearEvaluation.map((riskLevel) => {
        const riskLevelName = riskLevel.riskLevel
        riskLevel.count.map((dateObject) => {
            if (timeSortType === "YEARS") {
                graphData.map((year, index) => {
                    if (year.monthNumber === dateObject.year) {
                        graphData[index] = { ...graphData[index], [riskLevelName]: Number(dateObject.count) }
                    }
                })
            } else if (timeSortType === "MONTHS") {
                graphData.map((month, index) => {
                    if (month.monthNumber === dateObject.month) {
                        graphData[index] = { ...graphData[index], [riskLevelName]: Number(dateObject.count) }
                    }
                })
            } else if (timeSortType === 'DATES') {
                graphData.map((date, index) => {
                    if (date.monthNumber === moment(dateObject.date).date() - 1) {
                        graphData[index] = { ...graphData[index], [riskLevelName]: Number(dateObject.count) }
                    }
                })
            } else if (timeSortType === "DAYS") {
                graphData.map((date, index) => {
                    if (date.monthNumber - 1 === moment.weekdays().indexOf(dateObject.day)) {
                        graphData[index] = { ...graphData[index], [riskLevelName]: Number(dateObject.count) }
                    }
                })
            } else {
                graphData[dateObject.hour] = { ...graphData[dateObject.hour], [riskLevelName]: Number(dateObject.count) }
            }
        })
    })
    // saraaaa saraleeee saraaa kushkush ole!!!!!!!
    return graphData
}
export default BringTheCorrectDataArray;