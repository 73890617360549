import {  RISK_LEVEL, useStatisticsContext, whiteSquareSizeType } from '../../common';
import { GenericHeader, GenericPieChart, GenericWhiteSquare } from '../../generic-components';
import NoData from '../NoData';

import '../../style/GeneralStatistics.scss';

const RiskLevelDistributionSecondPart = ({ height, width }: whiteSquareSizeType) => {
    const { data } = useStatisticsContext();
    const COLORS = ["#FF886F","#FFD674","#00B4A9"]
    const pieData = data.evaluation !== null && data.evaluation.map((answer, index) => {
        if (answer.count !== 0) {
            return {
                name: RISK_LEVEL[answer.riskLevel],
                value: answer.count,
                color: COLORS[index]
            }
        }
        else {
            return {
                name: "",
                value: 0,
                color: "white"
            }
        }
    })

    return (
        <div>
            <GenericHeader text="התפלגות רמת מסוכנות" />
            <GenericWhiteSquare width={width} height={height}>
                {data.evaluation === null
                    ? <NoData />
                    : <GenericPieChart pieData={pieData} hole={false} bottom tooltipContent={'השאלונים שנענו'} onClickType='RISK_LEVEL' label={true} />}
            </GenericWhiteSquare>
        </div>
    );
};

export default RiskLevelDistributionSecondPart;
