import { PARENT_SECTION, useStatisticsContext, whiteSquareSizeType } from '../../common';
import { GenericHeader, GenericPieChart } from '../../generic-components';
import GenericWhiteSquare from '../../generic-components/WhiteSquare';
import NoData from '../NoData';

import '../../style/GeneralStatistics.scss';

const AverageScoreParentSectionForthPart = ({ height, width }: whiteSquareSizeType) => {
    const { data } = useStatisticsContext();
    const COLORS = ["#0088AF", "#00B4A9", "#FFD674"]
    const pieData = data.sectionsStatistics !== null && data.sectionsStatistics.parentSectionStatistics.map((section, index) => {
        return {
            name: PARENT_SECTION[section.name],
            value: section.average,
            color: COLORS[index]
        }
    })
    const noDataTest = data.sectionsStatistics !== null && pieData.every(data => data.value === 0)
    return (
        <div>
            <GenericHeader text="ניקוד ממוצע ביחס לפרקים" />
            <GenericWhiteSquare width={width} height={height}>
                {data.sectionsStatistics === null || noDataTest
                    ? <NoData />
                    : <GenericPieChart pieData={pieData} hole={false} label={true} bottom={true} tooltipContent={'ממוצע הניקוד'} />}
            </GenericWhiteSquare>
        </div>
    );
};

export default AverageScoreParentSectionForthPart;