import { useStatisticsContext } from "../../common"
import { GenericHeader, GenericWhiteSquare } from "../../generic-components"
import NoData from "../NoData"

import '../../style/AnswersStatistics.scss'

const PositiveAnswersDangerQuestionsSecondPart = () => {
    const { data } = useStatisticsContext()
    const orderData = data.sectionsStatistics !== null && data.sectionsStatistics.highRiskStatistics.sort((a, b) => b.number - a.number)
    const noDataTest = data.sectionsStatistics !== null &&orderData.every(data=> data.number === 0)
    return (
        <div className="answers-statistics-second-part">
            <div >
                <GenericHeader text={"מענה חיובי לפי שאלות הסכנה"} />
                <GenericWhiteSquare height="38vh" width="74vw">
                    <div className="high-risk-answers-container ">

                        {data.sectionsStatistics === null || noDataTest
                            ? <NoData />
                            : orderData.map((ques, index) => {
                                const present = Number(ques.number) === 0 ? 0 : JSON.stringify(ques.number).slice(0, ques.number < 10 ? 3 : 4)
                                const text = `${present}%`
                                return (
                                    <div className='high-risk-answers-data' key={index}>
                                        <div className='high-risk-answer-number'>{text}</div>
                                        <div className='high-risk-answer-text'>{ques.text}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </GenericWhiteSquare>
            </div>
        </div>
    )
}
export default PositiveAnswersDangerQuestionsSecondPart